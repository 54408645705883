import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { TogglePill, Toggle } from '@meetup/swarm-components';
import PillExample from '../../components/examples/togglePill';
import SwitchExample from '../../components/examples/toggle';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`TogglePill`}</h1>
    <hr></hr>
    <p>{`Toggle pills are used to indicate whether an option has been selected`}</p>
    <h2>{`Examples`}</h2>
    <PillExample mdxType="PillExample" />
    <h2>{`Props`}</h2>
    <PropsTable propMetaData={TogglePill.__docgenInfo} mdxType="PropsTable" />
    <h2>{`Snippets`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<>
<TogglePill label="swarm" checked={1 == 1} name="test-1" />
<TogglePill checked={1 == 0} name="test-1">Swarm Toggle</TogglePill>
</>
`}</code></pre>
    <h2>{`a11y`}</h2>
    <p>{`Pass `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to provide a more specific label to be used with screen readers.  Toggle Pills should support keyboad interaction with both `}<inlineCode parentName="p">{`space`}</inlineCode>{` and `}<inlineCode parentName="p">{`enter`}</inlineCode>{` keys.`}</p>
    <br />
    <br />
    <h1>{`Toggle Switch`}</h1>
    <hr></hr>
    <p>{`Toggle switches are used to indicate whether something is on or off.`}</p>
    <h2>{`Examples`}</h2>
    <SwitchExample mdxType="SwitchExample" />
    <h2>{`Props`}</h2>
    <PropsTable propMetaData={Toggle.__docgenInfo} mdxType="PropsTable" />
    <h2>{`Snippets`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<>
<Toggle checked={1 == 1} name="test-1"/>
<Toggle checked={1 == 0} name="test-1"/>
</>
`}</code></pre>
    <h2>{`a11y`}</h2>
    <p>{`Remember to associate the label to the Toggle. Toggles should support keyboad interaction with both `}<inlineCode parentName="p">{`space`}</inlineCode>{` and `}<inlineCode parentName="p">{`enter`}</inlineCode>{` keys.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      